import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React from "react"
import Layout from "../components/layout/layout"

export default function Aanmelden({ location }: { location: WindowLocation }) {
  return (
    <Layout title="Facebook" location={location}>
      <div className="page">
        <Heading>Facebook</Heading>
        <div style={{ margin: "1rem 0 " }}>
          Stalletjes in de buurt, kraampjes van kwekers en boerderijwinkels met
          acties zijn natuurlijk het leukst te volgen als ze bij jou in de buurt
          zitten. Volg Fairsy daarom per provincie op Facebook via: <br />
          <br />
          <p>
            Groningen -{" "}
            <a href="https://www.facebook.com/Fairsy-Groningen-107028981125276\">
              Fairsy Facebook Groningen
            </a>
            <br />
            Friesland -{" "}
            <a href="https://www.facebook.com/Fairsy-Friesland-103642194807167">
              Fairsy Facebook Friesland
            </a>
            <br />
            Drenthe -{" "}
            <a href="https://www.facebook.com/Fairsy-Drenthe-112869667204618">
              Fairsy Facebook Drenthe
            </a>
            <br />
            Overijssel -{" "}
            <a href="https://www.facebook.com/Fairsy-Overijssel-108521777647446">
              Fairsy Facebook Overijssel
            </a>
            <br />
            Gelderland -{" "}
            <a href="https://www.facebook.com/Fairsy-Gelderland-104645138037643">
              Fairsy Facebook Gelderland
            </a>
            <br />
            Utrecht -{" "}
            <a href="https://www.facebook.com/Fairsy-Utrecht-110249757478234">
              Fairsy Facebook Utrecht
            </a>
            <br />
            Noord-Holland{" "}
            <a href="https://www.facebook.com/Fairsy-Noord-Holland-108991647595700">
              Fairsy Facebook Noord Holland
            </a>
            <br />
            Zuid-Holland -{" "}
            <a href="https://www.facebook.com/stalletjes">
              Fairsy Facebook Zuid Holland (incl. Westland)
            </a>
            <br />
            Zeeland -{" "}
            <a href="https://www.facebook.com/Fairsy-Zeeland-110919554075261">
              Fairsy Facebook Zeeland
            </a>
            <br />
            Noord Brabant -{" "}
            <a href="https://www.facebook.com/Fairsy-Noord-Brabant-105208404701710\">
              Fairsy Facebook Noord Brabant
            </a>
            <br />
            Limburg -{" "}
            <a href="https://www.facebook.com/Fairsy-Limburg-112931327206563\">
              Fairsy Facebook Limburg
            </a>
            <br />
            Flevoland -{" "}
            <a href="https://www.facebook.com/Fairsy-Flevoland-106584067842193">
              Fairsy Facebook Flevoland
            </a>
            <br />
          </p>
          <p>
            Algemeen Fairsy account -{" "}
            <a href="https://www.facebook.com/Fairsy24">
              Fairsy Facebook algemeen
            </a>
          </p>
          <br />
        </div>
      </div>
    </Layout>
  )
}
